import React, {
  FormEvent,
  FormEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  checkTokenAndRenderCode,
  isTokenValid,
} from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../reusable-components/AuthContext';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import {
  GenericSearchableSelect,
  OptionElementType,
} from '../reusable-components/GenericSearchableSelect';
import { useAddNewJob } from '../hooks/add-new-job';
import { useGetAllOptions } from '../hooks/get-all-options';
import { TextInput } from '../reusable-components/TextInput';
import { uploadFileForJob } from '../services/job-api';

export const AddJobPage = () => {
  // constants
  const [error, setError] = React.useState<string>('');
  const [company, setCompany] = React.useState<OptionElementType | undefined>(
    undefined,
  );
  const [companiesOptions, setCompaniesOptions] = React.useState<
    OptionElementType[]
  >([]);
  const [user, setUser] = useState<OptionElementType | undefined>(undefined);
  const [usersOptions, setUsersOptions] = useState<OptionElementType[]>([]);

  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const {
    fileTypeOptions,
    invoiceTypeOptions,
    serviceProviderOptions,
    networkProviderOptions,
    getAllCompaniesOptions,
    getAllUsersOptions,
  } = useGetAllOptions(token);

  const [fileType, setFileType] = React.useState<OptionElementType>(
    fileTypeOptions[0],
  );
  const [invoiceType, setInvoiceType] = React.useState<OptionElementType>(
    invoiceTypeOptions[0],
  );
  const [networkProvider, setNetworkProvider] =
    React.useState<OptionElementType>(networkProviderOptions[1]);
  const [serviceProvider, setServiceProvider] =
    React.useState<OptionElementType>(serviceProviderOptions[1]);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();
  const { queryInProgress, setQueryInProgress, addNewJob } = useAddNewJob(
    token,
    setError,
    fileType,
    invoiceType,
    networkProvider,
    serviceProvider,
    company,
    user,
  );
  const [fileName, setFileName] = React.useState<string>('');
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);

  useEffect(() => {
    setQueryInProgress(true);
    // Start both asynchronous computations
    const companiesPromise = getAllCompaniesOptions();
    const usersPromise = getAllUsersOptions();

    // Use Promise.all to wait for both promises to resolve
    Promise.all([companiesPromise, usersPromise])
      .then(([companiesOptions, usersOptions]) => {
        // Set the state for companies and users options
        setCompaniesOptions(companiesOptions);
        setUsersOptions(usersOptions);

        setQueryInProgress(false);
      })
      .catch((error) => {
        alert(
          'Error while searching for users and companies. Maybe the session has expired?',
        );
        console.error('Error while searching for users and companies', error);
        setQueryInProgress(false);
      });
  }, [token]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e: FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    const userId = user?.value ?? '0';
    console.log(`UserId: ${userId}`);
    console.log('Uploading file...');

    if (fileBlob) {
      const formData = new FormData();
      // take the fileName without the full path:
      const myFileName = fileName.split('\\').pop() ?? 'myFileName';
      formData.append('file', fileBlob as Blob, myFileName);

      await uploadFileForJob(userId, formData);
    } else {
      addNewJob();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // File is already a Blob, but if you need to process it further:
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const blob = new Blob([arrayBuffer]);
        setFileBlob(blob);
        console.log('Blob created:', blob);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const pageJSX: JSX.Element = (
    <div className="md:container md:mx-auto px-10">
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <h1>Add New Job</h1>
        <GenericSearchableSelect
          labelText="File Type:"
          initialValue={fileType}
          options={fileTypeOptions}
          onChange={setFileType}
        />
        <GenericSearchableSelect
          labelText="Invoice Type:"
          initialValue={invoiceType}
          options={invoiceTypeOptions}
          onChange={setInvoiceType}
        />
        <GenericSearchableSelect
          labelText="Network Provider:"
          initialValue={networkProvider}
          options={networkProviderOptions}
          onChange={setNetworkProvider}
        />
        <GenericSearchableSelect
          labelText="Service Provider:"
          initialValue={serviceProvider}
          options={serviceProviderOptions}
          onChange={setServiceProvider}
        />
        <GenericSearchableSelect
          labelText="User:"
          initialValue={
            user ?? { value: '0', title: 'Select a user...', description: '' }
          }
          options={usersOptions}
          onChange={setUser}
          data_testid="user-dropdown"
        />
        <GenericSearchableSelect
          labelText="Company:"
          initialValue={
            company ?? {
              value: '0',
              title: 'Select a company...',
              description: '',
            }
          }
          options={companiesOptions}
          onChange={setCompany}
          data_testid="company-dropdown"
        />
        <TextInput
          label="Upload File:"
          fieldType="file"
          data-testid="file-upload"
          onChange={(e) => {
            setFileName(e.target.value);
            handleFileChange(e);
          }}
          value={fileName}
        />
        <div className="max-w-xs py-4 flex justify-center">
          <button
            type="submit"
            className="w-full btn btn-primary w-40"
            data-testid="add-new-job-button"
            disabled={
              queryInProgress ||
              !isTokenValid(token) ||
              !user ||
              !company ||
              !fileType ||
              !invoiceType ||
              !networkProvider ||
              !serviceProvider
            }
          >
            Start New Job
          </button>
        </div>
      </form>
      <div className="max-w-xs flex justify-center">
        {queryInProgress ? (
          <div className="loading loading-spinner loading-lg w-10"></div>
        ) : (
          <div data-testid="add-job-page-error-div" className="text-red-500">
            {error}
          </div>
        )}
      </div>
    </div>
  );
  return checkTokenAndRenderCode(token, pageJSX, setCurrentRoute, navigate);
};
