import qs from 'qs';
import {
  sendFormDataClient,
  sendJsonPayloadClient,
  callPostStringOperationEndpoint,
  multipartFormClient,
} from './api-main';
import { AddJobPayload } from '../models/job/AddJobPayload';
import { DeleteJobPayload } from '../models/job/DeleteJobPayload';
import {
  SUCCESS_RESPONSE,
  StringOperationResponse,
} from '../models/base/StringOperationResponse';
import { JobSearchPayload } from '../models/job/JobSearchPayload';
import { JobSearchResult } from '../models/job/JobSearchResult';

export const postSearchJob = async (
  payload: JobSearchPayload,
): Promise<JobSearchResult[]> => {
  try {
    // Convert the parameters to URL-encoded string
    const parameters = qs.stringify(payload);
    const { data } = await sendFormDataClient.post('/job/search', parameters);
    return data as JobSearchResult[];
  } catch (error) {
    console.error('Error searching for job:', error);
    throw error;
  }
};

export const postAddJob = async (
  payload: AddJobPayload,
): Promise<StringOperationResponse> => {
  return await callPostStringOperationEndpoint({
    endpointPath: '/batchRating/processBatchRatingJob',
    payload,
    client: sendJsonPayloadClient,
    stringifyPayload: false,
  });
};

export const postDeleteJob = async (
  payload: DeleteJobPayload,
): Promise<StringOperationResponse> => {
  return await callPostStringOperationEndpoint({
    endpointPath: '/job/deleteJob',
    payload,
    client: sendFormDataClient,
  });
};

export const uploadFileForJob = async (
  userId: string,
  body: FormData,
): Promise<StringOperationResponse> => {
  try {
    const payload = { userId };
    const parameters = qs.stringify(payload);
    const response = await multipartFormClient.post(
      `/batchRating/uploadZIPFile?${parameters}`,
      body,
    );
    console.log('response:', response);
    if (response?.data === SUCCESS_RESPONSE) {
      return { success: true };
    } else {
      return { success: false, userMessage: response?.data };
    }
  } catch (error) {
    console.error('Error searching for job:', error);
    throw error;
  }
};
